import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PrivacyHero from "../components/sections/PrivacyHero";
import GenericSection from "../components/sections/GenericSection";

export function PrivacyPolicy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Fragment>
      <PrivacyHero className="illustration-section-02" title="Privacy Policy" />
      <GenericSection topDivider>
        <div className="container-xs">
          <div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n",
              }}
            />
            <div data-custom-class="body">
              <div
                align="center"
                className="MsoNormal"
                data-custom-class="title"
                style={{ textAlign: "left", lineHeight: "1.5" }}
              >
                <strong>
                  <span style={{ lineHeight: "22.5px", fontSize: 26 }}>
                    PRIVACY NOTICE
                  </span>
                </strong>
              </div>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <strong>
                    <span data-custom-class="subtitle">
                      Last updated <bdt className="question">June 26, 2020</bdt>
                    </span>
                  </strong>
                </span>
              </p>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <br />
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Thank you for choosing to be part of our community at{" "}
                    <bdt className="question">AE Studio</bdt>
                    <bdt className="block-component" /> (“
                    <bdt className="block-component" />
                    <strong>Company</strong>
                    <bdt className="statement-end-if-in-editor" />
                    ”, “<strong>we</strong>”, “<strong>us</strong>”, or “
                    <strong>our</strong>”). We are committed to protecting your
                    personal information and your right to privacy. If you have
                    any questions or concerns about this privacy notice, or our
                    practices with regards to your personal information, please
                    contact us at <bdt className="question">info@ae.studio</bdt>
                    .
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    When you <bdt className="block-component" />
                    visit our website{" "}
                    <bdt className="question">
                      <a
                        href="https://messagethemlater.com"
                        target="_blank"
                        data-custom-class="link"
                      >
                        https://messagethemlater.com
                      </a>
                    </bdt>{" "}
                    (the "<strong>Website</strong>"),{" "}
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" /> and more generally, use
                    any of our services (the "<strong>Services</strong>", which
                    include the{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    ), we appreciate that you are trusting us with your personal
                    information. We take your privacy very seriously. In this
                    privacy notice, we seek to explain to you in the clearest
                    way possible what information we collect, how we use it and
                    what rights you have in relation to it. We hope you take
                    some time to read through it carefully, as it is important.
                    If there are any terms in this privacy notice
                    <span style={{ color: "rgb(89, 89, 89)" }}>&nbsp;</span>that
                    you do not agree with, please discontinue use of our
                    Services immediately.
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    This privacy notice applies to all information collected
                    through our Services (which, as described above, includes
                    our <bdt className="block-component" />
                    Website
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <bdt className="block-component">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  ), as well as any related services, sales, marketing or
                  events.
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <strong>
                    <span data-custom-class="body_text">
                      Please read this privacy notice
                      <span style={{ color: "rgb(89, 89, 89)" }}>&nbsp;</span>
                      carefully as it will help you understand what we do with
                      the information that we collect.
                    </span>
                  </strong>
                </span>
              </p>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <br />
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span style={{ fontSize: 19 }}>
                      <span data-custom-class="heading_1">
                        TABLE OF CONTENTS
                      </span>
                    </span>
                  </strong>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infocollect">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </a>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    2. HOW DO WE USE YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#infoshare">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </a>
                  <bdt className="block-component" />
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#sociallogins">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </span>
                    </span>
                  </span>
                </a>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#inforetain">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infosafe">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                </a>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#privacyrights">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    7. WHAT ARE YOUR PRIVACY RIGHTS?
                  </span>
                </a>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#DNT">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    8. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </a>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#caresidents">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </a>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#policyupdates">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </a>
              </p>
              <p style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#contact">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </a>
              </p>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <br />
                </span>
              </p>
              <p id="infocollect" style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span style={{ fontSize: 19 }}>
                      <span data-custom-class="heading_1">
                        1. WHAT INFORMATION DO WE COLLECT?
                      </span>
                    </span>
                  </strong>
                </span>
                <bdt className="block-component">
                  <span data-custom-class="body_text" />
                </bdt>
              </p>
              <p style={{ fontSize: 15 }}>
                <strong>
                  <span style={{ fontSize: 16 }}>
                    <span data-custom-class="heading_2">
                      <strong>
                        <span data-custom-class="heading_2">
                          <br />
                          Personal information you disclose to us
                        </span>
                      </strong>
                    </span>
                  </span>
                </strong>
              </p>
              <p style={{ fontSize: 15 }}>
                <strong>
                  <span data-custom-class="body_text">
                    <em>
                      <span style={{ fontSize: 15 }}>In Short: &nbsp;</span>
                    </em>
                  </span>
                </strong>
                <span data-custom-class="body_text">
                  <em>
                    <span style={{ fontSize: 15 }}>
                      We collect information that you provide to us.
                    </span>
                  </em>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    We collect personal information that you voluntarily provide
                    to us when you <bdt className="block-component" />
                    register on the <bdt className="block-component" />
                    Website, <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    express an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the{" "}
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                    <bdt className="block-component" /> (such as by posting
                    messages in our online forums or entering competitions,
                    contests or giveaways)
                    <bdt className="statement-end-if-in-editor" /> or otherwise
                    when you contact us.
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    The personal information that we collect depends on the
                    context of your interactions with us and the{" "}
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      Website
                      <bdt className="statement-end-if-in-editor" />
                      <bdt className="block-component" />
                      <bdt className="block-component" />
                    </span>
                    , the choices you make and the products and features you
                    use. The personal information we collect may include the
                    following:
                    <bdt className="block-component" />
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>Personal Information Provided by You.</strong> We
                    collect <bdt className="forloop-component" />
                    <bdt className="question">
                      contact or authentication data
                    </bdt>
                    ; <bdt className="forloop-component" />
                    <bdt className="question">timezone</bdt>;{" "}
                    <bdt className="forloop-component" />
                    and other similar information.
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor" />
                    <bdt className="block-component">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </p>
              <p style={{ fontSize: 15 }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>Social Media Login Data.</strong> We may provide you
                    with the option to register with us using your existing
                    social media account details, like your Facebook, Twitter or
                    other social media account. If you choose to register in
                    this way, we will collect the information described in the
                    section called "
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <a data-custom-class="link" href="#sociallogins">
                          HOW DO WE HANDLE YOUR SOCIAL LOGINS
                        </a>
                        " below.
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="statement-end-if-in-editor" />
                    </bdt>
                  </span>
                </span>
              </p>
              <div>
                <span data-custom-class="body_text">
                  All personal information that you provide to us must be true,
                  complete and accurate, and you must notify us of any changes
                  to such personal information.
                  <span style={{ fontSize: 15 }}>
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="statement-end-if-in-editor" />
                    </bdt>
                  </span>
                </span>
                <bdt className="block-component" />
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="statement-end-if-in-editor">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor">
                                  <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component" />
                                  </bdt>
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span
                          style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                            >
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor">
                                  <bdt className="block-component" />
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </bdt>
                    </bdt>
                  </span>
                </span>
                <bdt className="block-component" />
                <p />
                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                  <br />
                </p>
                <p id="infouse" style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span style={{ fontSize: 19 }}>
                        <span data-custom-class="heading_1">
                          2. HOW DO WE USE YOUR INFORMATION?
                        </span>
                      </span>
                    </strong>
                  </span>
                </p>
                <p style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <em>
                      <strong>In Short: &nbsp;</strong>We process your
                      information for purposes based on legitimate business
                      interests, the fulfillment of our contract with you,
                      compliance with our legal obligations, and/or your
                      consent.
                    </em>
                  </span>
                </p>
                <p style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    We use personal information collected via our{" "}
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    for a variety of business purposes described below. We
                    process your personal information for these purposes in
                    reliance on our legitimate business interests, in order to
                    enter into or perform a contract with you, with your
                    consent, and/or for compliance with our legal obligations.
                    We indicate the specific processing grounds we rely on next
                    to each purpose listed below.
                  </span>
                  <bdt className="block-component" />
                </p>
                <p style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    We use the information we collect or receive:
                  </span>
                  <bdt className="block-component" />
                </p>
                <ul>
                  <li>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <strong>
                              <span data-custom-class="body_text">
                                To facilitate account creation and logon
                                process.
                              </span>
                            </strong>
                            <span data-custom-class="body_text">
                              &nbsp;If you choose to link your account with us
                              to a third-party account (such as your Google or
                              Facebook account), we use the information you
                              allowed us to collect from those third parties to
                              facilitate account creation and logon process for
                              the performance of the contract.{" "}
                              <bdt className="block-component" />
                              See the section below headed "
                            </span>
                          </span>
                        </span>
                        <span data-custom-class="body_text">
                          <a data-custom-class="link" href="#sociallogins">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                HOW DO WE HANDLE YOUR SOCIAL LOGINS
                              </span>
                            </span>
                          </a>
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              " for further information.
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            To post testimonials.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;We post testimonials on our{" "}
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>{" "}
                          that may contain personal information. Prior to
                          posting a testimonial, we will obtain your consent to
                          use your name and the consent of the testimonial. If
                          you wish to update, or delete your testimonial, please
                          contact us at <bdt className="block-component" />
                          <bdt className="question">info@ae.studio</bdt>
                          <bdt className="else-block" /> and be sure to include
                          your name, testimonial location, and contact
                          information.
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            Request feedback.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;We may use your information to request feedback
                          and to contact you about your use of our{" "}
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          .
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            To enable user-to-user communications.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;We may use your information in order to enable
                          user-to-user communications with each user's consent.
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            To
                                                                            manage
                                                                            user
                                                                            accounts
                                                                          </strong>
                                                                          . We
                                                                          may
                                                                          use
                                                                          your
                                                                          information
                                                                          for
                                                                          the
                                                                          purposes
                                                                          of
                                                                          managing
                                                                          our
                                                                          account
                                                                          and
                                                                          keeping
                                                                          it in
                                                                          working
                                                                          order.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </li>
                </ul>
                <p style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </p>
                <p style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </p>
                <ul>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            Fulfill and manage your orders.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;We may use your information to fulfill and
                          manage your orders, payments, returns, and exchanges
                          made through the{" "}
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          .
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            Administer prize draws and competitions.&nbsp;
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          We may use your information to administer prize draws
                          and competitions when you elect to participate in our
                          competitions.
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            To
                                                                            deliver
                                                                            and
                                                                            facilitate
                                                                            delivery
                                                                            of
                                                                            services
                                                                            to
                                                                            the
                                                                            user.
                                                                          </strong>{" "}
                                                                          We may
                                                                          use
                                                                          your
                                                                          information
                                                                          to
                                                                          provide
                                                                          you
                                                                          with
                                                                          the
                                                                          requested
                                                                          service.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            To
                                                                            respond
                                                                            to
                                                                            user
                                                                            inquiries/offer
                                                                            support
                                                                            to
                                                                            users.
                                                                          </strong>{" "}
                                                                          We may
                                                                          use
                                                                          your
                                                                          information
                                                                          to
                                                                          respond
                                                                          to
                                                                          your
                                                                          inquiries
                                                                          and
                                                                          solve
                                                                          any
                                                                          potential
                                                                          issues
                                                                          you
                                                                          might
                                                                          have
                                                                          with
                                                                          the
                                                                          use of
                                                                          our
                                                                          Services.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <bdt className="statement-end-if-in-editor" />
                  </li>
                </ul>
                <p style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </p>
                <p style={{ fontSize: 15 }}>
                  <bdt className="block-component" />
                </p>
                <p style={{ fontSize: 15 }}>
                  <bdt className="block-component">
                    <bdt className="statement-end-if-in-editor" />
                  </bdt>
                </p>
                <p style={{ fontSize: 15 }}>
                  <br />
                </p>
                <p id="infoshare" style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span style={{ fontSize: 19 }}>
                        <span data-custom-class="heading_1">
                          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                        </span>
                      </span>
                    </strong>
                  </span>
                </p>
                <p style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <strong>
                      <em>
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">In Short:</span>
                        </span>
                        &nbsp;
                      </em>
                      &nbsp;
                    </strong>
                    <span style={{ fontSize: 15 }}>
                      <em>
                        <span data-custom-class="body_text">
                          We only share information with your consent, to comply
                          with laws, to provide you with services, to protect
                          your rights, or to fulfill business obligations.
                        </span>
                      </em>
                    </span>
                  </span>
                </p>
                <div>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      We may process or share your data that we hold based on
                      the following legal basis:
                    </span>
                  </span>
                </div>
                <ul>
                  <li>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <strong>Consent:</strong> We may process your data if
                        you have given us specific consent to use your personal
                        information in a specific purpose.
                      </span>
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <strong>Legitimate Interests:</strong> We may process
                        your data when it is reasonably necessary to achieve our
                        legitimate business interests.
                      </span>
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <strong>Performance of a Contract:&nbsp;</strong>Where
                        we have entered into a contract with you, we may process
                        your personal information to fulfill the terms of our
                        contract.
                      </span>
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <strong>Legal Obligations:</strong> We may disclose your
                        information where we are legally required to do so in
                        order to comply with applicable law, governmental
                        requests, a judicial proceeding, court order, or legal
                        process, such as in response to a court order or a
                        subpoena (including in response to public authorities to
                        meet national security or law enforcement requirements).
                      </span>
                      <br />
                      <br />
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <strong>Vital Interests:</strong> We may disclose your
                        information where we believe it is necessary to
                        investigate, prevent, or take action regarding potential
                        violations of our policies, suspected fraud, situations
                        involving potential threats to the safety of any person
                        and illegal activities, or as evidence in litigation in
                        which we are involved.
                      </span>
                    </span>
                  </li>
                </ul>
                <p style={{ fontSize: 15 }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        More specifically, we may need to process your data or
                        share your personal information in the following
                        situations:
                      </span>
                    </span>
                  </span>
                </p>
                <ul>
                  <li>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <strong>
                          <span data-custom-class="body_text">
                            Business Transfers.
                          </span>
                        </strong>
                        <span data-custom-class="body_text">
                          &nbsp;We may share or transfer your information in
                          connection with, or during negotiations of, any
                          merger, sale of company assets, financing, or
                          acquisition of all or a portion of our business to
                          another company.
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div>
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <div>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </span>
                        <div>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </div>
                        <ul>
                          <li>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <span data-custom-class="body_text">
                                    Other Users.
                                  </span>
                                </strong>
                                <span data-custom-class="body_text">
                                  &nbsp;When you share personal information{" "}
                                  <bdt className="block-component" />
                                  (for example, by posting comments,
                                  contributions or other content to the{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                  ){" "}
                                  <bdt className="statement-end-if-in-editor" />
                                  or otherwise interact with public areas of the{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                  , such personal information may be viewed by
                                  all users and may be publicly made available
                                  outside the{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>{" "}
                                  in perpetuity.{" "}
                                  <bdt className="block-component" />
                                  If you interact with other users of our{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>{" "}
                                  and register for our{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>{" "}
                                  through a social network (such as Facebook),
                                  your contacts on the social network will see
                                  your name, profile photo, and descriptions of
                                  your activity.{" "}
                                  <bdt className="statement-end-if-in-editor" />
                                  Similarly, other users will be able to view
                                  descriptions of your activity, communicate
                                  with you within our{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                  , and view your profile.
                                </span>
                              </span>
                            </span>
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div>
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                          <div>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="block-component" />
                            </span>
                            <span
                              style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                            >
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt className="block-component">
                                        <span data-custom-class="heading_1" />
                                      </bdt>
                                    </span>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <p />
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <br />
                              </span>
                            </p>
                            <p id="sociallogins" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <em>
                                    <span data-custom-class="body_text">
                                      If you choose to register or log in to our
                                      services using a social media account, we
                                      may have access to certain information
                                      about you.
                                    </span>
                                  </em>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    Our{" "}
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>{" "}
                                    offers you the ability to register and login
                                    using your third-party social media account
                                    details (like your Facebook or Twitter
                                    logins). Where you choose to do this, we
                                    will receive certain profile information
                                    about you from your social media provider.
                                    The profile Information we receive may vary
                                    depending on the social media provider
                                    concerned, but will often include your name,
                                    email address, friends list, profile picture
                                    as well as other information you choose to
                                    make public on such social media platform.{" "}
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We will use the information we receive only
                                  for the purposes that are described in this
                                  privacy notice
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    &nbsp;
                                  </span>
                                  or that are otherwise made clear to you on the
                                  relevant{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                              Website
                                              <bdt className="statement-end-if-in-editor" />
                                              <bdt className="block-component" />
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  . Please note that we do not control, and are
                                  not responsible for, other uses of your
                                  personal information by your third-party
                                  social media provider. We recommend that you
                                  review their privacy notice to understand how
                                  they collect, use and share your personal
                                  information, and how you can set your privacy
                                  preferences on their sites and apps.
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: 15,
                                      }}
                                    >
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                              <bdt className="block-component">
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component" />
                                                  <bdt className="block-component" />
                                                </span>
                                              </bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <br />
                              </span>
                            </p>
                            <p id="inforetain" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      5. HOW LONG DO WE KEEP YOUR INFORMATION?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <em>
                                    <span data-custom-class="body_text">
                                      We keep your information for as long as
                                      necessary to fulfill the purposes outlined
                                      in this privacy notice
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        &nbsp;
                                      </span>
                                      unless otherwise required by law.
                                    </span>
                                  </em>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    We will only keep your personal information
                                    for as long as it is necessary for the
                                    purposes set out in this privacy notice,
                                    unless a longer retention period is required
                                    or permitted by law (such as tax, accounting
                                    or other legal requirements). No purpose in
                                    this notice will require us keeping your
                                    personal information for longer than{" "}
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                the period of time in which users have an
                                account with us
                                <bdt className="block-component" />
                              </span>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="else-block" />.
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  When we have no ongoing legitimate business
                                  need to process your personal information, we
                                  will either delete or anonymize such
                                  information, or, if this is not possible (for
                                  example, because your personal information has
                                  been stored in backup archives), then we will
                                  securely store your personal information and
                                  isolate it from any further processing until
                                  deletion is possible.
                                </span>
                              </span>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="block-component" />
                              </span>
                            </p>
                            <div style={{ lineHeight: "1.5" }}>
                              <br />
                            </div>
                            <p id="infosafe" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <em>
                                    <span data-custom-class="body_text">
                                      We aim to protect your personal
                                      information through a system of
                                      organizational and technical security
                                      measures.
                                    </span>
                                  </em>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We have implemented appropriate technical and
                                  organizational security measures designed to
                                  protect the security of any personal
                                  information we process. However, despite our
                                  safeguards and efforts to secure your
                                  information, no electronic transmission over
                                  the Internet or information storage technology
                                  can be guaranteed to be 100% secure, so we
                                  cannot promise or guarantee that hackers,
                                  cybercriminals, or other unauthorized third
                                  parties will not be able to defeat our
                                  security, and improperly collect, access,
                                  steal, or modify your information. Although we
                                  will do our best to protect your personal
                                  information, transmission of personal
                                  information to and from our{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                              Website
                                              <bdt className="statement-end-if-in-editor" />
                                              <bdt className="block-component" />
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>{" "}
                                  is at your own risk. You should only access
                                  the{" "}
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                              Website
                                              <bdt className="statement-end-if-in-editor" />
                                              <bdt className="block-component" />
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>{" "}
                                  within a secure environment.
                                </span>
                              </span>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <br />
                              </span>
                            </p>
                            <p id="privacyrights" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      7. WHAT ARE YOUR PRIVACY RIGHTS?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <em>
                                      <bdt className="block-component" />
                                      You may review, change, or terminate your
                                      account at any time.
                                    </em>
                                  </span>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    If you are resident in the European Economic
                                    Area and you believe we are unlawfully
                                    processing your personal information, you
                                    also have the right to complain to your
                                    local data protection supervisory authority.
                                    You can find their contact details here:
                                    <span style={{ fontSize: 15 }}>&nbsp;</span>
                                    <span style={{ color: "rgb(48, 48, 241)" }}>
                                      <span data-custom-class="body_text">
                                        <a
                                          data-custom-class="link"
                                          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                          </span>
                                        </a>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(48, 48, 241)" }}>
                                  <span style={{ fontSize: 15 }}>.</span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    If you are resident in Switzerland, the
                                    contact details for the data protection
                                    authorities are available here:{" "}
                                    <span style={{ color: "rgb(48, 48, 241)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <a
                                            data-custom-class="link"
                                            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            https://www.edoeb.admin.ch/edoeb/en/home.html
                                          </a>
                                          .
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="block-component">
                                    <span data-custom-class="body_text" />
                                  </bdt>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    If you have questions or comments about your
                                    privacy rights, you may email us at{" "}
                                    <bdt className="question">
                                      info@ae.studio
                                    </bdt>
                                    .
                                  </span>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </p>
                            <div style={{ lineHeight: "1.1" }}>
                              <br />
                            </div>
                            <div>
                              <span style={{ fontSize: 16 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span data-custom-class="heading_2">
                                      Account Information
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </div>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    If you would at any time like to review or
                                    change the information in your account or
                                    terminate your account, you can:
                                    <bdt className="forloop-component" />
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    Upon your request to terminate your account,
                                    we will deactivate or delete your account
                                    and information from our active databases.
                                    However, we may retain some information in
                                    our files to prevent fraud, troubleshoot
                                    problems, assist with any investigations,
                                    enforce our Terms of Use and/or comply with
                                    applicable legal requirements.
                                  </span>
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <strong>
                                    <u>
                                      <span data-custom-class="body_text">
                                        Opting out of email marketing:
                                      </span>
                                    </u>
                                    &nbsp;
                                  </strong>
                                  <span data-custom-class="body_text">
                                    You can unsubscribe from our marketing email
                                    list at any time by clicking on the
                                    unsubscribe link in the emails that we send
                                    or by contacting us using the details
                                    provided below. You will then be removed
                                    from the marketing email list – however, we
                                    may still communicate with you, for example
                                    to send you service-related emails that are
                                    necessary for the administration and use of
                                    your account, to respond to service
                                    requests, or for other non-marketing
                                    purposes. To otherwise opt-out, you may:
                                    <bdt className="forloop-component" />
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span style={{ fontSize: 15 }}>
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <br />
                            </p>
                            <p id="DNT" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      8. CONTROLS FOR DO-NOT-TRACK FEATURES
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    Most web browsers and some mobile operating
                                    systems and mobile applications include a
                                    Do-Not-Track (“DNT”) feature or setting you
                                    can activate to signal your privacy
                                    preference not to have data about your
                                    online browsing activities monitored and
                                    collected. At this stage, no uniform
                                    technology standard for recognizing and
                                    implementing DNT signals has been finalized.
                                    As such, we do not currently respond to DNT
                                    browser signals or any other mechanism that
                                    automatically communicates your choice not
                                    to be tracked online. If a standard for
                                    online tracking is adopted that we must
                                    follow in the future, we will inform you
                                    about that practice in a revised version of
                                    this privacy notice.
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <br />
                            </p>
                            <p id="caresidents" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                                      PRIVACY RIGHTS?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <em>
                                    <span data-custom-class="body_text">
                                      Yes, if you are a resident of California,
                                      you are granted specific rights regarding
                                      access to your personal information.
                                    </span>
                                  </em>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    California Civil Code Section 1798.83, also
                                    known as the “Shine The Light” law, permits
                                    our users who are California residents to
                                    request and obtain from us, once a year and
                                    free of charge, information about categories
                                    of personal information (if any) we
                                    disclosed to third parties for direct
                                    marketing purposes and the names and
                                    addresses of all third parties with which we
                                    shared personal information in the
                                    immediately preceding calendar year. If you
                                    are a California resident and would like to
                                    make such a request, please submit your
                                    request in writing to us using the contact
                                    information provided below.
                                  </span>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  If you are under 18 years of age, reside in
                                  California, and have a registered account with{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                            the Website
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  , you have the right to request removal of
                                  unwanted data that you publicly post on the{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                            Website
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  . To request removal of such data, please
                                  contact us using the contact information
                                  provided below, and include the email address
                                  associated with your account and a statement
                                  that you reside in California. We will make
                                  sure the data is not publicly displayed on the{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: 15 }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                            Website
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  , but please be aware that the data may not be
                                  completely or comprehensively removed from all
                                  our systems (e.g. backups, etc.). &nbsp;
                                </span>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </p>
                            <div style={{ lineHeight: "1.1" }}>
                              <br />
                            </div>
                            <div>
                              <span style={{ fontSize: 16 }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                  <strong>
                                    <span data-custom-class="heading_2">
                                      CCPA Privacy Notice
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </div>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  The California Code of Regulations defines a
                                  "resident" as:
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, marginLeft: 20 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  (1) every individual who is in the State of
                                  California for other than a temporary or
                                  transitory purpose and
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, marginLeft: 20 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  (2) every individual who is domiciled in the
                                  State of California who is outside the State
                                  of California for a temporary or transitory
                                  purpose
                                </span>
                              </span>
                            </p>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  All other individuals are defined as
                                  "non-residents."
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  If this definition of "resident" applies to
                                  you, certain rights and obligations apply
                                  regarding your personal information.
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    What categories of personal information do
                                    we collect?
                                  </strong>
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We have collected the following categories of
                                  personal information in the past twelve (12)
                                  months: <bdt className="block-component" />
                                </span>
                              </span>
                            </div>
                            <div>
                              <span data-custom-class="body_text">
                                <br />
                              </span>
                            </div>
                            <table
                              style={{
                                width: "100%",
                                marginRight: "calc(0%)",
                                marginLeft: "calc(0%)",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderWidth: 1,
                                      borderColor: "black",
                                      borderStyle: "solid",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>Category</strong>
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderTop: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>Examples</strong>
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      borderTop: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>Collected</strong>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderBottom: "1px solid black",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      A. Identifiers
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderBottom: "1px solid black",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Contact details, such as real name, alias,
                                      postal address, telephone or mobile
                                      contact number, unique personal
                                      identifier, online identifier, Internet
                                      Protocol address, email address and
                                      account name
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      B. Personal information categories listed
                                      in the California Customer Records statute
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Name, contact information, education,
                                      employment, employment history and
                                      financial information
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      YES
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      C. Protected classification
                                      characteristics under California or
                                      federal law
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Gender and date of birth
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      D. Commercial information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Transaction information, purchase history,
                                      financial details and payment information
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      E. Biometric information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderBottom: "1px solid black",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Fingerprints and voiceprints
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <span data-custom-class="body_text">
                                      <br />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      F. Internet or other similar network
                                      activity
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Browsing history, search history, online
                                      behavior, interest data, and interactions
                                      with our and other websites, applications,
                                      systems and advertisements
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      YES
                                      <bdt className="else-block" />
                                    </span>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      G. Geolocation data
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Device location
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      YES
                                      <bdt className="else-block" />
                                    </span>
                                    <br />
                                    <span data-custom-class="body_text">
                                      <br />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      H. Audio, electronic, visual, thermal,
                                      olfactory, or similar information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Images and audio, video or call recordings
                                      created in connection with our business
                                      activities
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <span data-custom-class="body_text">
                                      <br />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      I. Professional or employment-related
                                      information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Business contact details in order to
                                      provide you our services at a business
                                      level, job title as well as work history
                                      and professional qualifications if you
                                      apply for a job with us
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <span data-custom-class="body_text">
                                      <br />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      J. Education Information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Student records and directory information
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                    <br />
                                    <span data-custom-class="body_text">
                                      <br />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: "33.4086%",
                                      borderLeft: "1px solid black",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      K. Inferences drawn from other personal
                                      information
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "51.7352%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      Inferences drawn from any of the collected
                                      personal information listed above to
                                      create a profile or summary about, for
                                      example, an individual’s preferences and
                                      characteristics
                                      <br />
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      width: "14.9677%",
                                      borderRight: "1px solid black",
                                      borderBottom: "1px solid black",
                                      textAlign: "center",
                                    }}
                                  >
                                    <br />
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      NO
                                    </span>
                                    <bdt className="statement-end-if-in-editor">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                    <br />
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div>
                              <bdt className="block-component" />
                            </div>
                            <div>
                              <br />
                            </div>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We may also collect other personal information
                                  outside of these categories in instances where
                                  you interact with us in-person, online, or by
                                  phone or mail in the context of:
                                </span>
                              </span>
                            </p>
                            <ul>
                              <li>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      Receiving help through our customer
                                      support channels
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <br />
                              </li>
                              <li>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      Participation in customer surveys or
                                      contests; and
                                    </span>
                                  </span>
                                </span>
                                <br />
                                <br />
                              </li>
                              <li>
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      Facilitation in the delivery of our
                                      Services and to respond to your inquiries
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    How do we use and share your personal
                                    information?
                                  </strong>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component">
                                    <bdt className="block-component" />
                                  </bdt>
                                </span>
                              </span>
                            </p>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  More information about our data collection and
                                  sharing practices can be found in this privacy
                                  notice
                                </span>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  .
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  You may contact us{" "}
                                  <bdt className="block-component" />
                                  by email at{" "}
                                  <bdt className="question">info@ae.studio</bdt>
                                  ,{" "}
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  <bdt className="block-component">
                                    <span data-custom-class="body_text" />
                                  </bdt>
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  or by referring to the contact details at the
                                  bottom of this document.
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  If you are using an authorized agent to
                                  exercise your right to opt-out, we may deny a
                                  request if the authorized agent does not
                                  submit proof that they have been validly
                                  authorized to act on your behalf.
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        Will your information be shared with
                                        anyone else?
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We may disclose your personal information with
                                  our service providers pursuant to a written
                                  contract between us and each service provider.
                                  Each service provider is a for-profit entity
                                  that processes the information on our behalf.
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We may use your personal information for our
                                  own business purposes, such as for undertaking
                                  internal research for technological
                                  development and demonstration. This is not
                                  considered to be "selling" of your personal
                                  data.
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="question">AE Studio</bdt> has
                                  not disclosed or sold any personal information
                                  to third parties for a business or commercial
                                  purpose in the preceding twelve (12) months.{" "}
                                  <bdt className="question">AE Studio</bdt> will
                                  not sell personal information in the future
                                  belonging to website visitors, users and other
                                  consumers.
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </div>
                            <div>
                              <br />
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <strong>
                                      Your rights with respect to your personal
                                      data
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <u>
                                      Right to request deletion of the data -
                                      Request to delete
                                    </u>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    You can ask for the deletion of your
                                    personal information. If you ask us to
                                    delete your personal information, we will
                                    respect your request and delete your
                                    personal information, subject to certain
                                    exceptions provided by law, such as (but not
                                    limited to) the exercise by another consumer
                                    of his or her right to free speech, our
                                    compliance requirements resulting from a
                                    legal obligation or any processing that may
                                    be required to protect against illegal
                                    activities.
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <u>
                                      Right to be informed - Request to know
                                    </u>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    Depending on the circumstances, you have a
                                    right to know:
                                  </span>
                                </span>
                              </span>
                            </div>
                            <ul>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        whether we collect and use your personal
                                        information;
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        the categories of personal information
                                        that we collect;
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <div>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        the purposes for which the collected
                                        personal information is used;
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        whether we sell your personal
                                        information to third parties;
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        the categories of personal information
                                        that we sold or disclosed for a business
                                        purpose;
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <div>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        the categories of third parties to whom
                                        the personal information was sold or
                                        disclosed for a business purpose; and
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        the business or commercial purpose for
                                        collecting or selling personal
                                        information.
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                            </ul>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    In accordance with applicable law, we are
                                    not obligated to provide or delete consumer
                                    information that is de-identified in
                                    response to a consumer request or to
                                    re-identify individual data to verify a
                                    consumer request.
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <u>
                                      Right to Non-Discrimination for the
                                      Exercise of a Consumer's Privacy Rights
                                    </u>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    We will not discriminate against you if you
                                    exercise your privacy rights.
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <u>Verification process</u>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    Upon receiving your request, we will need to
                                    verify your identity to determine you are
                                    the same person about whom we have the
                                    information in our system. These
                                    verification efforts require us to ask you
                                    to provide information so that we can match
                                    it with the information you have previously
                                    provided us. For instance, depending on the
                                    type of request you submit, we may ask you
                                    to provide certain information so that we
                                    can match the information you provide with
                                    the information we already have on file, or
                                    we may contact you through a communication
                                    method (e.g. phone or email) that you have
                                    previously provided to us. We may also use
                                    other verification methods as the
                                    circumstances dictate.
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    We will only use personal information
                                    provided in your request to verify your
                                    identity or authority to make the request.
                                    To the extent possible, we will avoid
                                    requesting additional information from you
                                    for the purposes of verification. If,
                                    however, if we cannot verify your identity
                                    from the information already maintained by
                                    us, we may request that you provide
                                    additional information for the purposes of
                                    verifying your identity, and for security or
                                    fraud-prevention purposes. We will delete
                                    such additionally provided information as
                                    soon as we finish verifying you.
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <br />
                              </span>
                            </div>
                            <div>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <u>Other privacy rights</u>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <ul>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        you may object to the processing of your
                                        personal data
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        you may request correction of your
                                        personal data if it is incorrect or no
                                        longer relevant, or ask to restrict the
                                        processing of the data
                                      </span>
                                    </span>
                                  </span>
                                </p>
                              </li>
                              <li>
                                <div>
                                  <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        you can designate an authorized agent to
                                        make a request under the CCPA on your
                                        behalf. We may deny a request from an
                                        authorized agent that does not submit
                                        proof that they have been validly
                                        authorized to act on your behalf in
                                        accordance with the CCPA.
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <p style={{ fontSize: 15 }}>
                                  <span
                                    style={{
                                      color: "rgb(0, 0, 0)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      you may request to opt-out from future
                                      selling of your personal information to
                                      third parties. Upon receiving a request to
                                      opt-out, we will act upon the request as
                                      soon as feasibly possible, but no later
                                      than 15 days from the date of the request
                                      submission.
                                    </span>
                                  </span>
                                </p>
                              </li>
                            </ul>
                            <div>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  To exercise these rights, you can contact us{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      by email at{" "}
                                      <bdt className="question">
                                        info@ae.studio
                                      </bdt>
                                      ,{" "}
                                      <bdt className="statement-end-if-in-editor" />
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      <bdt className="block-component">
                                        <span data-custom-class="body_text" />
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  or by referring to the contact details at the
                                  bottom of this document. If you have a
                                  complaint about how we handle your data, we
                                  would like to hear from you.
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: 15,
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <br />
                              </span>
                            </p>
                            <p
                              id="policyupdates"
                              style={{ fontSize: 15, lineHeight: "1.5" }}
                            >
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      10. DO WE MAKE UPDATES TO THIS NOTICE?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <strong>
                                  <em>
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        In Short:
                                      </span>
                                    </span>
                                    &nbsp;
                                  </em>
                                  &nbsp;
                                </strong>
                                <span style={{ fontSize: 15 }}>
                                  <em>
                                    <span data-custom-class="body_text">
                                      Yes, we will update this notice as
                                      necessary to stay compliant with relevant
                                      laws.
                                    </span>
                                  </em>
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  We may update this privacy notice
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    &nbsp;
                                  </span>
                                  from time to time. The updated version will be
                                  indicated by an updated “Revised” date and the
                                  updated version will be effective as soon as
                                  it is accessible. If we make material changes
                                  to this privacy notice, we may notify you
                                  either by prominently posting a notice of such
                                  changes or by directly sending you a
                                  notification. We encourage you to review this
                                  privacy notice
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    &nbsp;
                                  </span>
                                  frequently to be informed of how we are
                                  protecting your information.
                                </span>
                              </span>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <br />
                              </span>
                            </p>
                            <p id="contact" style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span style={{ fontSize: 19 }}>
                                    <span data-custom-class="heading_1">
                                      11. HOW CAN YOU CONTACT US ABOUT THIS
                                      NOTICE?
                                    </span>
                                  </span>
                                </strong>
                              </span>
                            </p>
                            <p style={{ fontSize: 15 }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: 15,
                                }}
                              >
                                <span data-custom-class="body_text">
                                  If you have questions or comments about this
                                  notice, you may{" "}
                                  <bdt className="block-component" />
                                  email us at{" "}
                                  <bdt className="question">info@ae.studio</bdt>
                                  <bdt className="statement-end-if-in-editor" />{" "}
                                  or by post to:
                                </span>
                              </span>
                            </p>
                            <div>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="question">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      AE Studio
                                    </span>
                                  </span>
                                  &nbsp;
                                </bdt>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="question">
                                      1434 Abbot Kinney
                                    </bdt>
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="question">Los Angeles</bdt>
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />,{" "}
                                    <bdt className="question">CA</bdt>
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />{" "}
                                    <bdt className="question">90291</bdt>
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="question">
                                      United States
                                    </bdt>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span style={{ fontSize: 15 }}>
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <bdt className="else-block" />
                                  </span>
                                </span>
                              </span>
                              <bdt className="statement-end-if-in-editor" />
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                              <bdt className="block-component" />
                            </div>
                            <p
                              id="contact"
                              style={{ fontSize: 15, lineHeight: "1.5" }}
                            >
                              <br />
                            </p>
                            <p id="contact" style={{ fontSize: 15 }}>
                              <strong>
                                <span data-custom-class="heading_1">
                                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                                  WE COLLECT FROM YOU?
                                </span>
                              </strong>
                            </p>
                            <div>
                              <span data-custom-class="body_text">
                                Based on the applicable laws of your country,
                                you may have the right to request access to the
                                personal information we collect from you, change
                                that information, or delete it in some
                                circumstances. To request to review, update, or
                                delete your personal information, please{" "}
                                <bdt className="block-component" />
                                submit a request form by clicking{" "}
                                <span style={{ color: "rgb(48, 48, 241)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <a
                                        data-custom-class="link"
                                        href="https://app.termly.io/notify/cc7b7a7d-4f81-4cd8-8d63-2c7c44220221"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        here
                                      </a>
                                    </span>
                                  </span>
                                </span>
                                <bdt className="block-component" />. We will
                                respond to your request within 30 days.
                              </span>
                            </div>
                            <style
                              dangerouslySetInnerHTML={{
                                __html:
                                  "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: "#595959",
                              fontSize: 14,
                              fontFamily: "Arial",
                              paddingTop: 16,
                            }}
                          >
                            This privacy policy was created using{" "}
                            <a
                              style={{ color: "rgb(48, 48, 241) !important" }}
                              href="https://termly.io/products/privacy-policy-generator/?ftseo"
                            >
                              Termly’s Privacy Policy Generator
                            </a>
                            .
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ;
        </div>
      </GenericSection>
    </React.Fragment>
  );
}
