import classNames from "classnames";
import React from "react";
import { SectionSplitProps } from "../../utils/SectionProps";
import { AddToSlackButton } from "../AddToSlackButton";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class HeroSplit extends React.Component {
  componentDidMount() {
    // this is only to handle inline style on window resize
    window.onresize = function () {
      this.forceUpdate();
    }.bind(this);
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item" style={inlineStyle()}>
                <div className="hero-content split-item-content center-content-mobile">
                  <h1
                    className="mt-0 mb-16 reveal-from-top"
                    data-reveal-delay="150"
                  >
                    Message Them Later
                  </h1>
                  <p
                    className="mt-0 mb-32 reveal-from-top"
                    data-reveal-delay="300"
                  >
                    A free Slack message scheduler for people who don't want to
                    be annoying assholes.
                  </p>
                  <div className="reveal-from-top" data-reveal-delay="450">
                    <AddToSlackButton />
                  </div>
                  <p
                    className="mt-0 reveal-from-top"
                    data-reveal-delay="300"
                    style={{ paddingTop: "40px" }}
                  >
                    Made with{" "}
                    <span role="img" aria-label="love">
                      ❤️
                    </span>{" "}
                    by <a href="https://ae.studio">AE Studio</a>
                  </p>
                  <p
                    className="mt-0 mb-32 reveal-from-top"
                    data-reveal-delay="300"
                    style={{ fontSize: 14 }}
                  >
                    We made this for free but we can also make whatever cool
                    stuff you want for $,{" "}
                    <a href="https://ae.studio/#robot-here">hit us up</a> 😉
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// inline style
const inlineCss = {
  alignItems: "flex-start",
  minHeight: "492px",
};

const inlineStyle = function () {
  if (window.innerWidth > 641) {
    return inlineCss;
  }
};

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;
