import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner pt-0",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
      "tiles-wrap illustration-section-08",
      pushLeft && "push-left"
    );

    const sectionHeader = {
      title: "Don’t take my word for it",
      paragraph:
        "Take the word of these users who may or may not happen to also be my friends.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/katie_cropped.jpg")}
                        alt="Testimonial 01"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — I used to get messages from my bosses all the time and
                        there was no good filter to know which were urgent so
                        I'd read them all no matter the hour. Since they made
                        our developers make this app, I hardly ever get messages
                        after 10pm and have been able to take up the new hobby
                        of cooking potatoes in various ways while listening to
                        hardcore rap.
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Katie James
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="https://ae.studio">AE Studio</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/ed.jpg")}
                        alt="Testimonial 02"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — Since I've started watching Tiger King and regularly
                        staying up until 2am, I have a lot of thoughts about it,
                        but don't want my coworkers to think that I need
                        immediate help fixing a bad bug in production, so I
                        `/schedule` my non work related messages so everyone has
                        memes to delight them throughout the day instead.
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Ed
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="https://mrdeeplearning.com">
                          Mr. Deep Learning
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="tiles-item reveal-from-top"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("./../../assets/images/testimonial-image-03.jpg")}
                        alt="Testimonial 03"
                        width={56}
                        height={56}
                      />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        — Slack you Later has saved my marriage.
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Erika Muliawan
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">AppName</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
