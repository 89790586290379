import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="mailto:info@ae.studio">Contact</a>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms">Terms and Conditions</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
