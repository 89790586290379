import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { SectionProps } from "../../utils/SectionProps";
import { AddToSlackButton } from "../AddToSlackButton";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">Start messaging 'em later.</h3>
              <p
                className="mt-0 mb-32 reveal-from-top"
                data-reveal-delay="300"
                style={{ paddingTop: "40px" }}
              >
                Made with{" "}
                <span role="img" aria-label="love">
                  ❤️
                </span>{" "}
                by <a href="https://ae.studio">AE Studio</a>
              </p>
            </div>
            <div className="cta-action">
              <AddToSlackButton />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
