import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Send Messages into the Future",
      paragraph:
        "With our sophisticated time machine timey wimey technology, you can write a message now that will appear in a Slack channel at a designated time in the future. ",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
            >
              <Image
                className="mb-24"
                src={require("./../../assets/images/undraw_time.svg")}
                alt="Features split icon"
                width={80}
                height={80}
              />
            </SectionHeader>
            <div className={splitClasses}>
              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">DM at the Appropriate Time</h3>
                  <p className="m-0">
                    Great for bosses who like to constantly ask for stuff, but
                    want to minimize complaints about work/life balance.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-01",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgOddStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-01.png")}
                      alt="Features split top 01"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Post Scheduled Messages to Public and Private Channels
                  </h3>
                  <p className="m-0">
                    PRO TIP: Schedule in a channel with lots of people 'sigining
                    off now, lmk if you need anything and I'll get to it in the
                    AM' to go out several hours after you've stopped working.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-02",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                  <div style={imgEvenStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-02.png")}
                      alt="Features split top 02"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">It's Free</h3>
                  <p className="m-0">
                    This includes supppppper fancy NLP like understanding 'in 5
                    minutes' but otherwise it just lets you schedule messages.
                    If you want something fancier, please pay someone and bother
                    them.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-01",
                    imageFill && "split-item-image-fill"
                  )}
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                  <div style={imgOddStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-03.png")}
                      alt="Features split top 03"
                      width={624}
                      height={512}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgOddStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-6.31%",
  left: "-16.48%",
};

const imgEvenStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-6.31%",
  left: "-1.51%",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
