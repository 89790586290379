import React from "react";
import Accordion from "../elements/Accordion";
import AccordionItem from "../elements/AccordionItem";
import GenericSection from "./GenericSection";
import SectionHeader from "./partials/SectionHeader";

class FaqSection extends React.Component {
  render() {
    const genericSection04Header = {
      title: "FAQ",
    };

    return (
      <GenericSection topDivider className="illustration-section-06">
        <div className="container-xs">
          <SectionHeader
            data={genericSection04Header}
            className="center-content"
          />
          <Accordion>
            <AccordionItem title="How to schedule a message?">
              Type <code>/schedule</code> followed by the message, date and time
              that you want the message to be sent, for example:{" "}
              <code>
                /schedule Hello everyone! Don't forget that we have a special
                treat at the kitchen today! 1pm
              </code>{" "}
              and press enter.
              <br />
              You can also use natural language, like{" "}
              <code>/schedule Do you wanna make a call? in 5 minutes</code> or
              simply <code>/schedule</code> and a window will open, so you can
              compose your message, choose the date, time and destination.
            </AccordionItem>
            <AccordionItem title="How to list your scheduled messages?">
              Type <code>/schedule-list</code> and press enter to list all your
              scheduled messages.
              You can also type <code>/scheduled-here</code> and press enter to list 
              messages scheduled to the current conversation.
            </AccordionItem>
            <AccordionItem title="How to edit a scheduled message?">
              You can edit a scheduled message right after scheduling one, by
              clicking on the edit button. You can also list all messages using{" "}
              <code>/schedule-list</code> and click on the edit button to edit a
              specific message.
            </AccordionItem>
            <AccordionItem title="How to delete a scheduled message?">
              You can delete a scheduled message right after scheduling one, by
              clicking on the delete button. You can also list all messages
              using <code>/schedule-list</code> and click on the delete button
              to delete a specific message.
            </AccordionItem>
          </Accordion>
        </div>
      </GenericSection>
    );
  }
}

export default FaqSection;
