import React from "react";
import { Switch, withRouter } from "react-router-dom";
import LayoutAlternative from "./layouts/LayoutAlternative";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
// Views
import Home from "./views/Home";
import { PrivacyPolicy } from "./views/PrivacyPolicy";
import { Terms } from "./views/Terms";

class App extends React.Component {
  componentDidMount() {
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/privacy"
              component={PrivacyPolicy}
              layout={LayoutAlternative}
            />
            <AppRoute
              exact
              path="/terms"
              component={Terms}
              layout={LayoutAlternative}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
