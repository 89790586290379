import React from "react";

export const AddToSlackButton = () => (
  <a href="https://slack.com/oauth/v2/authorize?client_id=2371065813.1041112259222&scope=chat:write,commands,im:history,users:read,dnd:read&user_scope=channels:read,chat:write,groups:read,im:read,mpim:read">
    <img
      alt="Add to Slack"
      height="40"
      width="139"
      src="https://platform.slack-edge.com/img/add_to_slack.png"
      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
    />
  </a>
);
